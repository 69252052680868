<template>
  <div class="">
    <div class="apply-coupon-section">
        <div v-b-toggle.apply-coupon-section-collapse style="background: #F7F7F7;  cursor: pointer; border-bottom: 1px solid #D2D8E0; border-top: 1px solid #D2D8E0;" class="button-editor-heading px-3 py-2 d-flex justify-content-between align-items-center">
          <div style="color: #18191C; font-weight: 500; font-size: 16px" class="">Apply Coupon When</div>
          <div class="button-chevron" style="color: #B3AFB6; font-weight: 500; font-size: 16px" >
          <i class="fa fa-chevron-down " aria-hidden="true"></i>
        </div>
        </div>
        <b-collapse accordion="button-accordion" id="apply-coupon-section-collapse" class="mt-2">
         <div class="px-3">
          <div v-for="lead in leadData" :key="lead.id" class="d-flex mb-2 justify-content-between">
          <label for="" class="editor-label">{{ lead.Text }}</label>
          <b-form-checkbox  :value="lead"></b-form-checkbox>
          </div>
          <i class="text-danger" style="font-size:13px;">All of the selected values are provided by user</i>
         </div>
          </b-collapse>
          </div>
          <div class="text-coupon-section">
        <div v-b-toggle.text-coupon-section-collapse style="background: #F7F7F7;  cursor: pointer; border-bottom: 1px solid #D2D8E0; border-top: 1px solid #D2D8E0;" class="button-editor-heading px-3 py-2 d-flex justify-content-between align-items-center">
          <div style="color: #18191C; font-weight: 500; font-size: 16px" class="">Coupon Text Styles</div>
          <div class="button-chevron" style="color: #B3AFB6; font-weight: 500; font-size: 16px" >
          <i class="fa fa-chevron-down " aria-hidden="true"></i>
        </div>
        </div>
        <b-collapse accordion="button-accordion" id="text-coupon-section-collapse" class="mt-2">
         <div class="px-3">
          <label for="" class="editor-label">Text:</label>
      <input type="text" v-model="block.couponText" placeholder="Button text" name="button-text" class="editor-input-control w-100">
         </div>
         <hr>
         <div class="px-3">
          <FontFamily  v-model="block.style.fontFamily"></FontFamily>
  <div class="my-3 d-flex gap align-items-center" title="Font size (px)">
    <div class="d-flex align-items-center" style="gap:4px">
<span class="" style="color: #B3AFB6;"><svg width="19" height="13" viewBox="0 0 19 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.6666 3.825H18.3333L15.8333 0.5L13.3333 3.825H14.9999V9.675H13.3333L15.8333 13L18.3333 9.675H16.6666V3.825Z" fill="#B3AFB6"></path><path d="M0.833282 3V0.5H11.6666V3H7.49995V13H4.99995V3H0.833282Z" fill="#B3AFB6"></path></svg></span>
  <input type="number" v-model="block.style.fontSize" placeholder="Font size" class="editor-input-control w-100 input-bg-color border-0">
</div>
    <FontStyles :textBold.sync="block.style.fontWeight" 
  :textItalic.sync=" block.style.fontStyle"
  :textUnderLine.sync="block.style.textDecoration" class=""></FontStyles>
   
  </div>
<TextAlign v-model=block.style.textAlign ></TextAlign>
         </div>
         <hr>
         <div class="px-3">
  <div class="d-flex gap">
<div class="d-flex align-items-center" title="Line Height" style="gap:4px">
<span class="" style="color: #B3AFB6;" ><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5 5.83332H7.08333L4.16667 2.91666L1.25 5.83332H3.33333V14.1667H1.25L4.16667 17.0833L7.08333 14.1667H5V5.83332ZM8.33333 4.16666V5.83332H18.3333V4.16666H8.33333ZM8.33333 15.8333H18.3333V14.1667H8.33333V15.8333ZM8.33333 10.8333H18.3333V9.16666H8.33333V10.8333Z" fill="currentColor"></path></svg></span>
  <input type="number" v-model="block.style.lineHeight"  placeholder="Line Height" class="editor-input-control w-100 input-bg-color border-0">
</div>
<div class="d-flex align-items-center" title="Rotate" style="gap:4px">
<span class="" style="color: #B3AFB6;"><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.925 7.10834L4.75 5.92501C4 6.89168 3.53333 8.00834 3.39166 9.16668H5.075C5.19166 8.44168 5.48333 7.73334 5.925 7.10834ZM5.075 10.8333H3.39166C3.53333 11.9917 3.99166 13.1083 4.74166 14.075L5.91666 12.8917C5.48333 12.2667 5.19166 11.5667 5.075 10.8333ZM5.91666 15.2667C6.88333 16.0167 8.00833 16.4667 9.16666 16.6083V14.9167C8.44166 14.7917 7.74166 14.5083 7.11666 14.0583L5.91666 15.2667ZM10.8333 3.39168V0.833344L7.04166 4.62501L10.8333 8.33334V5.07501C13.2 5.47501 15 7.52501 15 10C15 12.475 13.2 14.525 10.8333 14.925V16.6083C14.125 16.2 16.6667 13.4 16.6667 10C16.6667 6.60001 14.125 3.80001 10.8333 3.39168Z" fill="currentColor"></path></svg></span>
  <input type="number"  v-model="block.style.rotate" placeholder="Rotate" class="editor-input-control w-100 input-bg-color border-0">
</div>
  </div>
 </div>
         <hr>
 <div class="px-3">
  <SelectColor v-model="block.style.color"  :opacity.sync="block.style.textOpacity" class="mt-3" :label="'Text Color'"></SelectColor>
  </div>
          </b-collapse>
          </div>
          <div class="button-coupon-section">
        <div v-b-toggle.button-coupon-section-collapse style="background: #F7F7F7;  cursor: pointer; border-bottom: 1px solid #D2D8E0; border-top: 1px solid #D2D8E0;" class="button-editor-heading px-3 py-2 d-flex justify-content-between align-items-center">
          <div style="color: #18191C; font-weight: 500; font-size: 16px" class="">Coupon Button Styles</div>
          <div class="button-chevron" style="color: #B3AFB6; font-weight: 500; font-size: 16px" >
          <i class="fa fa-chevron-down " aria-hidden="true"></i>
        </div>
        </div>
        <b-collapse accordion="button-accordion" id="button-coupon-section-collapse" class="mt-2">
         <div class="px-3">
          <label for="" class="editor-label">Text:</label>
      <input type="text" v-model="block.couponBtnText" placeholder="Text" name="button-text" class="editor-input-control w-100">
         </div>
         <hr>
         <div class="px-3">
          <FontFamily  v-model="block.style.btnFontFamily"></FontFamily>
  <div class="my-3 d-flex gap align-items-center" title="Font size (px)">
    <div class="d-flex align-items-center" style="gap:4px">
<span class="" style="color: #B3AFB6;"><svg width="19" height="13" viewBox="0 0 19 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.6666 3.825H18.3333L15.8333 0.5L13.3333 3.825H14.9999V9.675H13.3333L15.8333 13L18.3333 9.675H16.6666V3.825Z" fill="#B3AFB6"></path><path d="M0.833282 3V0.5H11.6666V3H7.49995V13H4.99995V3H0.833282Z" fill="#B3AFB6"></path></svg></span>
  <input type="number" v-model="block.style.btnFontSize" placeholder="Font size" class="editor-input-control w-100 input-bg-color border-0">
</div>
    <FontStyles :textBold.sync="block.style.btnFontWeight" 
  :textItalic.sync=" block.style.btnFontStyle"
  :textUnderLine.sync="block.style.btnTxtDecoration" class=""></FontStyles>
   
  </div>
<TextAlign v-model=block.style.btnTextAlign ></TextAlign>
         </div>
         <hr>
 <div class="px-3">
  <SelectColor v-model="block.style.btnBgColor"  :isOpacity="false" class="mt-3" :label="'Background Color:'"></SelectColor>
  <SelectColor v-model="block.style.btnColor"  :isOpacity="false" class="mt-3" :label="'Text Color'"></SelectColor>
  </div>
  <hr class="mb-0">
          </b-collapse>
          <div class="my-3">
            <div class="px-3">
          <label for="" class="editor-label">Coupon Link:</label>
      <input type="url" v-model="block.couponLink" placeholder="Coupon Link" name="coupon-link" class="editor-input-control w-100">
         </div>
         <hr>
         <div class="px-3">
          <SelectColor :label="'Coupon Background:'" :isOpacity="false" v-model="block.style.couponBg"></SelectColor>
         </div>
         <hr>
         <div class="px-3">
  <padding  v-if="isDesktopView" :right.sync="block.style.marginRight" :left.sync="block.style.marginLeft" :top.sync="block.style.marginTop" :bottom.sync="block.style.marginBottom"></padding>
  <padding  v-else :label="'Spacing: (Mobile)'"  :right.sync="block.style.mobileMarginRight" :left.sync="block.style.mobileMarginLeft" :top.sync="block.style.mobileMarginTop" :bottom.sync="block.style.mobileMarginBottom"></padding> 
</div>
        </div>
          </div>
  </div>
  </template>
  <script>
  
  import fontFamilyList from "../../../staticPages/fontFamilyList";
import FontFamily from "../MiniComponents/FontFamily.vue";
import FontStyles from "../MiniComponents/FontStyles.vue";
import Padding from "../MiniComponents/Padding.vue";
import SelectColor from "../MiniComponents/SelectColor.vue";
import TextAlign from "../MiniComponents/TextAlign.vue";
  export default {
    components: {
      FontFamily,
FontStyles,
TextAlign,
SelectColor,
Padding
    },
    props: {
      block: Object,
      index: Number,
       isDesktopView:Boolean,
    },
    data() {
      return {
       
        showBgOpacity:true,
        leadData:[
            {
                id:'email',
                value:'email',
                Text:"Email"
            },
            {
                id:'full_name',
                value:'full_name',
                Text:"Full Name"
            },
            {
                id:'phone_number',
                value:'phone_number',
                Text:"Phone Number"
            },
        ]
      };
    },
    computed:{
      getFontFamilyList() {
return fontFamilyList;
},
textFontFamily(){
return  this.block.style.fontFamily
},
btnFontFamily(){
  return this.block.style.btnFontFamily
}
    },
    methods:{
      checkHexCode(color){
        if(color){
          let pattern = /^#[0-9A-F]{6}$/i;
             return  color.match(pattern) ? true : false
        }
              
          },
      BgColorOutput(value){
        let isHex = this.checkHexCode(value)
        if(isHex){
          this.showBgOpacity = true
          return value
        } else{
          this.showBgOpacity = false
          return 'Gradient input'
        }
      },
      
    },
    watch:{
    textFontFamily(newVal,oldVal){
      if(oldVal && newVal){
        this.$store.dispatch('updateFontFamily', { oldFontFamily:oldVal, newFontFamily:newVal });
        this.$store.dispatch('loadFontsFromTracker');
      }
    },
    btnFontFamily(newVal,oldVal){
      if(oldVal && newVal){
        this.$store.dispatch('updateFontFamily', { oldFontFamily:oldVal, newFontFamily:newVal });
        this.$store.dispatch('loadFontsFromTracker');
      }
    },
  },
  };
  </script>
  <style >
 
  .reset-option
  {border: 0.3px solid #6D6B6D;
      box-sizing: border-box;
      border-radius: 4px;}
  .form-control-sn {
    border: 1px solid;
    width: 100%;
    border-radius: 3px;
    /* line-height: 1rem; */
    height: 26px;
    font-size: 14px;
  }
  
  .active-svg {
    padding: 3px;
    background: #b3afb6;
    border-radius: 3px;
    
  }
  .active-svg path{
     fill: #323232;
    
  }
  
   input[type="number"]::-webkit-outer-spin-button ,input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
  }
  
  .button-group {
    color: #535353;
    outline: none;
  }
  .btn-group {
    border: 1px solid #e0dee2;
    border-radius: 2px;
  }
  .btn-group .btn {
    border: none;
  }
  .text-img-btn-selected {
    background: #ffa201 !important;
    color: #ffffff !important;
  }
  
  
  
      .socialInput {
          background: transparent;
          border: none;
          outline: none;
          border-bottom:  1px solid #E0DEE2;
      }
      .socialInput:focus {
          border: none;
          outline: none;
          border-bottom:  1px solid #E0DEE2;
      }
      .socialInput::placeholder {
         font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      
      text-align: start;
      
      color: #D1D1D1;
      }

      .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #4d1b7e;
    
    border-radius: 4px;
    background-color: #4d1b7e;
}
.custom-control-input:checked ~ .custom-control-label::after {
    color: #fff;
    border-color: #4d1b7e;
    border-radius: 4px;
    background-color: #4d1b7e;
}
.custom-control-input:focus:not(:checked)~.custom-control-label::before {
    border-color: #4d1b7e;
    
    background-color: #fff;
}
  
  </style>
  